import React, { useState } from "react";
import { useRouter } from 'next/router';
import LazyLoad from 'react-lazy-load';
import Image from "next/image";

export type AuthorStatic = {
  name: string;
  url: string;
};

const AuthorPreview = (props: {
  author: AuthorStatic,
}) => {
  const { author } = props;
  const router = useRouter();
  const [hover, setHover] = useState(false);

  const handleClick = (author: AuthorStatic) => {
    router.push(`/articles?author=${author.name}`);
  };

  return (
    <div
      className="rounded shadow-lg overflow-hidden flex flex-col justify-between hover:text-red cursor-pointer"
      onClick={(e) => handleClick(author)}
      onMouseEnter={() => { setHover(true) }}
      onMouseLeave={() => { setHover(false) }}
      onKeyDown={(e) => {if (e.key === "Enter") handleClick(author)}}
      role="button"
      tabIndex={0}
      aria-label={`Author ${author.name}`}
    >
      <div className="flex justify-center p-8 pb-0">
      <LazyLoad
          threshold={0.95}
          className="rounded-full overflow-hidden translate3d backface-hidden w-full"
        >
          <Image
            className="transition-all transform scale-100 hover:scale-110 object-contain w-full"
            src={author.url}
            alt={author.name}
            width={0}
            height={0}
            sizes="100vw"
          />
        </LazyLoad>
      </div>
      <div className={`flex items-center justify-between pr-4 text-lg`}>
        <span className="text-lg p-4">{author.name}</span>
        <svg xmlns="http://www.w3.org/2000/svg" className={`transition-all ${hover ? "opacity-100" : "opacity-20" }`} fill="none" height="24" width="24" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
        </svg>
      </div>
    </div>
  )
}

export default AuthorPreview;
