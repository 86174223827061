import React, { useState } from "react";
import ReadMore from "../Buttons/ReadMore";
import { Article } from "../../../context/articlesContext/reducer";
import { useRouter } from 'next/router';
import Image from "next/image";
import { format } from "date-fns";
import { defaultImage } from "../../../utils/image";
import { convertDateToLocalDate } from "../../../utils/date";

const ArticlePreview = (props: {
  className?: string,
  hideDescription?: boolean,
  article?: Article
}) => {

  const [imageUrl, setImageUrl] = useState<string>(
    props.article?.image_url
      ? props.article.image_url
      : defaultImage
  );
  const router = useRouter();

  const handleNavigateToArticle = (e) => {
    e.preventDefault();
    router.push(`/articles/${props.article?.slug}`);
  };

  return (
    <div className={`rounded article-container shadow-lg flex flex-col overflow-hidden cursor-pointer relative ${ props.className }`} 
      onClick={(e) => handleNavigateToArticle(e)}
      onKeyDown={(e) => {if (e.key === "Enter") handleNavigateToArticle(e)}}
      role="button"
      tabIndex={0}
      aria-label="Navigate to Article"
    >
      <div className="relative">
        <div className="h-full overflow-hidden">
          <div className="article-image aspect-16/9 relative">
            <Image
              src={imageUrl}
              alt={props.article?.extracted_title || ""}
              fill
              style={{objectFit: "contain"}}
              sizes="(min-width: 640px) 50vw, 100vw"
              onError={() => {
                setImageUrl(defaultImage);
              }}
            />
          </div>
        </div>
      </div>
      <div className="p-6 flex flex-col justify-start h-full">
        <div className="flex-1">
          <p className="font-normal text-sm opacity-60 pb-2">
            {`${props.article?.slug === 'josh-raynolds-in-memoriam-1962-2023-mar-2023' ? "March 24, 2023" : format(convertDateToLocalDate(props.article?.date), "MMMM d, yyyy")} - ${props.article?.author}`}
          </p>
          <h2 className="transition-all text-xl font-bold pb-2" 
          >
            {props.article?.extracted_title}
          </h2>
          {
            !props.hideDescription &&
            <p className="text-base font-light">
              {props.article?.preview}
            </p>
          }
        </div>
        <ReadMore
          className="pt-12"
          link={`/articles/${props.article?.slug}`}
          needLink={false}
        />
      </div>
    </div>
  )
}

export default ArticlePreview;
