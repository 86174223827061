import React from "react";
import ReadMore from "../Buttons/ReadMore";
import { useRouter } from 'next/router';
import SectionWrapper from "../Section/SectionWrapper";
import Image from 'next/image'

const Banners = ({ banners }) => {
  const router = useRouter();

  const handleNavigate = (banner) => {
    router.push(banner.link);
  }

  return (
    <SectionWrapper
      title=""
    >
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 pt-16 xl:pt-0 xl:grid-cols-1 gap-4 pb-16">
        {
          banners.length > 0 && banners.map(banner => {
            return (
              <div key={banner.id} className={`rounded article-container shadow-lg flex flex-col overflow-hidden cursor-pointer relative`} 
                onClick={() => handleNavigate(banner)}
                onKeyDown={(e) => {if (e.key === "Enter") handleNavigate(banner)}}
                role="button"
                tabIndex={0}
                aria-label=""
              >
                <div className="relative overflow-hidden max-h-64 h-full">
                  <div className="article-image h-0 aspect-16/9 bg-cover bg-center hover relative">
                    <Image
                      src={banner.image_url}
                      alt=""
                      fill
                      style={{objectFit: "contain"}}
                      sizes="(min-width: 640px) 50vw, 100vw"
                    />
                  </div>
                </div>
                <div className="p-4 flex flex-col">
                  <ReadMore
                    label={
                      <div className="flex flex-col space-y-1">
                        <div>
                          {banner.section_title}
                        </div>
                        <div className="opacity-70 text-sm">
                          {banner.title} 
                        </div>
                      </div>
                    }
                    link={ banner.link }
                  />
                </div>
              </div>
            )
          })
        }
      </div>
    </SectionWrapper>
  )
}

export default Banners;
