import React from "react";

const SectionWrapper = (props: {
  title: string, 
  readMoreLabel?: string, 
  readMoreLink?: string, 
  className?: string, 
  children?: any
}) => {
  const titleClass = "text-xl md:text-3xl font-light w-full leading-1"
  return (
    <div>
      {
        props.readMoreLink != undefined ?
          <a className="flex justify-center items-end mb-4" href={ props.readMoreLink }>
            <h2 className={titleClass}>{ props.title }</h2>
            <div className="flex-shrink-0 flex justify-center items-center">
              <h3 className="block font-normal">{ props.readMoreLabel ? props.readMoreLabel : "View All" }</h3>
              <svg xmlns="http://www.w3.org/2000/svg" className="block transition-all ml-1 mb-1" fill="none" height="12" width="12" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 5l7 7-7 7M5 5l7 7-7 7" />
              </svg>
            </div>
          </a>
        :
          props.title.length > 0 ? <h2 className={titleClass}>{ props.title }</h2> : null
      }
      <section className={`${ props.className }`}>
        { props.children }
      </section>
    </div>
  )
}

export default SectionWrapper;